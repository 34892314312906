<template>
  <v-container
    fluid
    style="background-color:#f2f2f2 !important;min-height:100%;"
  >
    <!-- Filter Array -->
    <v-row
      class="ma-0 pa-0 px-2 mb-2"
      style="background-color:white !important;border: 1px solid teal;border-radius: 6px;"
    >
      <v-col cols="12" class="ma-0 pa-0 py-2 mr-2">
        <v-card flat style="background-color:transparent;">
          <v-card-title class="pa-0 pr-2">
            <date-range-picker @dateChange="onDateChange" initDateMode="thisWeek"/>

            <v-spacer/>
            <v-btn
              class="ma-2 pa-1 px-2"
              outlined
              @click="showCalendar = !showCalendar"
              :color="showCalendar ? '#AD1457' : 'grey'"
              min-width="0"
            >
              <v-icon>mdi-calendar-clock</v-icon>
            </v-btn>

            <div
              :class="{
                'ml-0 mt-0': $vuetify.breakpoint.smAndDown,
                'ml-1': $vuetify.breakpoint.mdAndUp,
              }"
              style="width:100%;"
            >
              <filter-array
                :filters="statusList"
                @change-filters="updateFilter"
              ></filter-array>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <!-- End Filter Array -->

    <v-row>
      <v-col
        :class="{
          'd-none': !allowShowCalendar,
        }"
        cols="12" md="12"
      >
        <mcv-calendar
          :data="displayCalendar"
          :allowShowEvent="true"
        ></mcv-calendar>
      </v-col>
      <v-col 
      :class="{
          'd-none': !allowShowTable,
        }"
      cols="12">
        <v-card flat style="border-radius:8px;min-height:320px;">
          <v-card-title class="py-2 px-2 mb-1" dark style="color:teal;">

            <template>
              <v-btn
                color="teal"
                light
                :disabled='!allowApprove'
                style="color:white;"
                class="text-none mr-2 mb-2"
                @click="approveSessions('approves')"
              >
                <v-icon small class="mr-2">mdi-checkbox-marked-circle</v-icon>
                Chấp Nhận
              </v-btn>
              <v-btn
                color="error"
                light
                :disabled='!allowReject'
                style="color:white;"
                class="text-none mr-2 mb-2"
                @click="showReason = true"
              >
                <v-icon small class="mr-2">mdi-alert</v-icon>
                Từ Chối
              </v-btn>
              <v-btn
                color="#E53935"
                light
                :disabled="!allowDelete"
                style="color:white;"
                class="text-none mr-2 mb-2"
                @click="deleteSessions"
              >
                <v-icon small class="mr-2">mdi-delete</v-icon>
                Xóa
              </v-btn>
            </template>
            <v-btn v-if="$root.roles.includes('user-manager')||$root.roles.includes('admin')"
              dark
              class="text-none mr-2 mb-2"
              @click="downloadExcel"
              color="green">
              <v-icon class="mr-2 ">
                mdi-download
              </v-icon>
              Download
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              outlined
              dense
              single-line
              hide-details
              style="max-width:320px;"
            ></v-text-field>
          </v-card-title>

          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <v-data-table
              flat
              v-model="selected_sessions"
              :headers="headers"
              :items="sessionsAfterFilter"
              :loading="loading"
              loading-text="Loading... Please wait"
              no-data-text="Chưa có lượt thuê nào"
              item-key="_id"
              :mobile-breakpoint="0"
              :items-per-page="pageSize"
              show-select
              single-select
              dense
              class="elevation-0"
              :search="searchString"
              :page.sync="depsPage"
              @page-count="pageCount = $event"
              hide-default-footer
              :sort-by="['start_time']"
              :sort-desc="[true]"
            >
              <template v-slot:item.start_time="{ item }">
                {{ resolveOfDateDisplay(item) }}
              </template>
              <template v-slot:item.props="{ item }">
                {{ getOffOrderStatusName(item.props.offType, item.props.pickedHours)}}
              </template>
              <!-- <template v-slot:item.created_at="{ item }">
                {{ $moment(item.created_at).format("HH:mm DD/MM/YYYY") }}
              </template> -->
              <template v-slot:item.status="{ item }">
                <div style="font-size:12px;font-weight:600;"
                  :style="{'color': getStatusColor(item.status)}">
                  {{getStatusName(item.status)}}
                </div>
              </template>
            </v-data-table>
            <div v-if="pageCount > 1" class="text-center pt-2">
              <v-pagination
                v-model="depsPage"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <reason-dialog
      :show="showReason"
      :title="'Lý do từ chối'"
      @fill-reason-done="fillReason"
      @cancel-fill-reason="
        showReason = false;
        reason = '';
      "
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import mixins_room from "@/mixins/Rooms/index.js";
let XLSX = require("xlsx");
export default {
  computed: {
    ...mapGetters({
      token: "token",
      userId: "userId",
    }),
    sessionsAfterFilter() {
      let self = this;
      let result = self.sessionsFullData;

      // console.log(this.$root.roles)

      if(!this.$root.roles.includes('admin') && !this.$root.roles.includes('user-manager')){
        let activeDepartment = null
        if(this.masterUser) activeDepartment = this.masterUser.department

        if(!activeDepartment) return []
        let tmpResult = result.filter(item => 
         { 
           console.log(item.user_name)
           console.log(item.user_department)
           return item.user_department && item.user_department == activeDepartment
         })
        result = tmpResult
        // console.log("List of item after filter user_department")
        // console.log(result)
      }

      


      // Filter of Time
      if (result.length > 0 && self.range.start > 0) {
        let filter = [];
        filter = result.filter(
          (s) =>
            (this.$moment(this.range.start).valueOf() <=
              this.$moment(s.start_time).valueOf() &&
              this.$moment(s.start_time).valueOf() <=
                this.$moment(this.range.end).valueOf()) ||
            (this.$moment(this.range.start).valueOf() <=
              this.$moment(s.end_time).valueOf() &&
              this.$moment(s.end_time).valueOf() <=
                this.$moment(this.range.end).valueOf())
        );
        result = filter;
      }
      
      
      // Filter of Status
      if (result.length > 0) {
        let filter = [];
        filter = result.filter((s) => self.filtersResult.includes(s.status));
        result = filter;
      }

      return result;
    },
    allowApprove: function () {
      let allow = true
      if(!this.selected_sessions || this.selected_sessions.length <= 0){
        allow = false
      }
      this.selected_sessions.forEach(session => {
        if(['APPROVED', 'REJECTED', 'CANCELLED'].includes(session.status)){
          allow = false
        }
      })
      return allow
    },
    allowReject () {
      let allow = true
      if(!this.selected_sessions || this.selected_sessions.length <= 0){
        allow = false
      }
      this.selected_sessions.forEach(session => {
        if(['REJECTED', 'CANCELLED'].includes(session.status)){
          allow = false
        }
      })
      return allow
    },
    allowDelete () {
      let allow = true
      if(!this.selected_sessions || this.selected_sessions.length <= 0){
        allow = false
      }
      return allow
    },
    allowShowTable() {
      return !this.showCalendar;
    },
    allowShowCalendar() {
      return this.showCalendar;
    },
    displayCalendar() {
      let result = [];
      
      let sessionsAfFilter = this.sessionsAfterFilter;
        if (!sessionsAfFilter || sessionsAfFilter.length <= 0) return [];
        sessionsAfFilter.forEach((item) => {
          let startTime = this.$moment(item.start_time).format(
            "YYYY-MM-DD HH:mm"
          );
          let endTime = this.$moment(item.end_time).format("YYYY-MM-DD HH:mm");
          if (
            this.$moment(item.start_time).valueOf() <
            this.$moment(this.range.start).valueOf()
          ) {
            startTime = this.$moment(this.range.start).format(
              "YYYY-MM-DD HH:mm"
            );
          }
          if (
            this.$moment(item.end_time).valueOf() >
            this.$moment(this.range.end).valueOf()
          ) {
            endTime = this.$moment(this.range.end).format("YYYY-MM-DD HH:mm");
          }

          let content = item.user_name ? item.user_name : "-";
          content = content + " - " + item.description;
          
          //Color
          let color = ""
          let typeObj = this.offTypeList.find( p => p.value == item.props.offType);
          if(!typeObj) color = "#263238"
          else color = typeObj.color;

          result.push({
            data: item,
            name: content,
            start: startTime,
            end: endTime,
            color: color,
            timed: true,
          });
        });

      return result;
    },
  },
  mixins: [mixins_room],
  data() {
    return {
      roles: ['user-off-manager', 'user-manager', 'admin'],
      sessions: [],
      sessionsFullData: [],
      selected_sessions: [],
      reason: "",
      showReason: false,
      departments: [],
      users: [],
      pageSize: 20,
      pageCount: 0,
      depsPage: 1,
      loading: false,
      searchString: "",
      headers: [
        {
          text: "Nhân viên",
          align: "start",
          sortable: true,
          value: "user_name",
        },
        {
          text: "Ngày nghỉ",
          align: "start",
          sortable: true,
          value: "start_time",
          // width: 140,
        },
        {
          text: "Hình thức",
          align: "start",
          sortable: true,
          value: "props",
          width: 140,
        },
        {
          text: "Lý do xin nghỉ",
          align: "start",
          sortable: false,
          value: "description",
          width: 240,
        },
        {
          text: "Trạng Thái",
          align: "center",
          sortable: true,
          value: "status",
          width: 100,
        },
        {
          text: "Phòng/ban",
          align: "left",
          sortable: true,
          value: "department_name",
          width: 120,
        },
      ],
      offTypeList: [
          {label: 'Nghỉ cả ngày', value:'full-date'},
          {label: 'Nghỉ buổi sáng', value:'off-morning'},
          {label: 'Nghỉ buổi chiều', value:'off-afternoon'},
          {label: 'Làm việc online', value:'work-from-home'},
          {label: 'Nghỉ không lương', value:'full-date-nopayment'},
          {label: 'Nghỉ chế độ: cưới xin-hiếu hỉ...', value:'full-date-legal'},
          {label: 'Vắng mặt', value:'absent'},
          {label: 'Làm việc online', value:'work-from-home'}
      ],
      statusList: [
        { selected: true, code: "WAIT_FOR_APPROVE", label: "Chờ duyệt", color:'orange' },
        { selected: true, code: "APPROVED", label: "Đã duyệt" , color:'green'},
        { selected: false, code: "REJECTED", label: "Đã từ chối" , color:'red'},
        { selected: false, code: "CANCELLED", label: "Đã hủy", color:'#888888'},
      ],
      filtersResult: [],
      menuDatePicker: false,
      rangeMode: "day",
      range: {
        start: this.$moment()
          .startOf("week")
          .toDate(),
        end: this.$moment()
          .endOf("week")
          .toDate(),
      },
      showCalendar: false,
      masterUser: null
    };
  },
  methods: {
    fillReason(value){
      this.reason = value;
      this.showReason = false;
      this.approveSessions('rejects')
    },
    onDateChange (dateRange) {
      console.log('onDateChange')
      this.range = dateRange.range
      this.rangeMode = dateRange.rangeMode
      this.fetchAllData()
    },
    updateFilter(filters) {
      this.filtersResult = filters
    },
    getStatusName (code) {
      let matchStatus = this.statusList.find(status => status.code == code)
      if(matchStatus) return matchStatus.label
      return ''
    },
    getStatusColor (code) {
      let matchStatus = this.statusList.find(status => status.code == code)
      if(matchStatus) return matchStatus.color
      return 'black'
    },
    resolveOfDateDisplay (rentSession) {
      let mStart = this.$moment(rentSession.start_time).format("DD/MM/YYYY")
      let mEnd = this.$moment(rentSession.end_time).format("DD/MM/YYYY")
      if(mStart != mEnd) {
        return mStart + ' - ' + mEnd
      } else {
        return mStart
      }
    },
    getOffOrderStatusName (code, pickedHours) {
      let matchStatus = this.offTypeList.find(s => s.value == code)
      if (matchStatus) {
          if(matchStatus.value=='absent' && pickedHours){
            let hourLabels = ' '
            pickedHours.forEach(hour => {
              if (hour.value ) {
                hourLabels += hour.hour+'h, '
              }
            })
            return matchStatus.label + hourLabels
          }
          return matchStatus.label
      }
      return ''
    },
    gotoSession (path) {
      console.log(path)
      return
      //this.$router.push({path: path})
    },
    processAfterFetchData() {
      if (this.sessions && this.sessions.length > 0) {
        this.sessions = this.sessions.filter((s) => !s.deleted);
        this.sessions.map((item) => {
          //Users
          let matchUser = this.users.find(i => i._id == item.user_id)
          if (matchUser) {
            item.user_code = matchUser.user_code
            item.user_name = matchUser.fullname
            item.user_department = matchUser.department
          } else {
            item.user_name = ""
            item.user_department = ""
          }

          //Departments
          if (!this.departments.find((i) => i._id == item.user_department)) {
            item.department_name = "-";
          } else {
            item.department_name = this.departments.find(
              (i) => i._id == item.user_department
            ).name;
          }
        });
        // console.log('Process data after fetch')
        // console.log(this.sessions)
        this.sessionsFullData = this.sessions
      }
    },
    setColorForOffOrder() {
      let self = this;
      self.offTypeList.forEach((property, idx) => {
        if (self.roomsColorArr[idx]) {
          property.color = self.roomsColorArr[idx];
        } else {
          property.color = "#263238";
        }
      });
    },
    fetchAllData() {
      let self = this;
      self.loading = true;
      self.sessions = [];
      self.selected_sessions = [];
      let paramsSessions = {
        // created_at_min: moment(self.params.timeRange.start).toISOString(),
        // created_at_max: moment(self.params.timeRange.end).toISOString(),
        // show_deleted: true,
      };

      let promiseArray =  [
        self.axios.get(self.$root.apiAssetMana + "/sessions?type=off-order&limit=10000", {
          headers: { "x-auth": this.token },
          params: paramsSessions,
        }),
        
      ]

      if(!self.users || self.users.length<=0) {
        promiseArray.push(
          self.axios.get(self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId))
        promiseArray.push(
          self.axios.get(self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId))
      }
        Promise.all(promiseArray).then((responseArray) => {
          if (responseArray[0].data.status == "OK") {
            self.sessions = responseArray[0].data.content.items;
          }
          if(!self.users || self.users.length<=0) {
            if (responseArray[1].data.status == "OK") {
              self.departments = responseArray[1].data.content.items;
            }
            if (responseArray[2].data.status == "OK") {
              self.users = responseArray[2].data.content.items;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // self.processTotalReport();
          self.processAfterFetchData();
          self.loading = false;
        });
    },
    async approveSessions(lockFunction) {
      if (!this.selected_sessions || this.selected_sessions.length <= 0) return;
      // let validateSes = await this.validateTimeBeforeApprove();
      // if (lockFunction == "approves" && !validateSes) {
      //   alert("Thời gian đặt phòng đã bị trùng!");
      //   return;
      // }

      let self = this;
      let reqData = {
        ids: this.selected_sessions
          .filter((s) => !["CANCELLED"].includes(s.status))
          .map((u) => u._id),
      };

      switch (lockFunction) {
        case "approves":
          reqData.confirmedBy = this.userId;
          reqData.ids = this.selected_sessions
            .filter(
              (s) => !["CANCELLED", "REJECTED", "APPROVED"].includes(s.status)
            )
            .map((u) => u._id);
          break;

        case "rejects":
          reqData.rejectedBy = this.userId;
          reqData.rejected_reason = this.reason;
          break;
      }

      this.axios
        .put(self.$root.apiAssetMana + "/sessions/" + lockFunction, reqData, {
          headers: { "x-auth": this.token },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            alert("Thành công!");
            self.fetchAllData();
          } else {
            alert("Đã xảy ra lỗi!");
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          alert("Đã xảy ra lỗi!");
          console.log(err);
        });
    },
    validateTimeBeforeApprove() {
      return new Promise((resolve) => {
        if (this.selected_sessions.length > 0) {
          let resultSessions = this.selected_sessions.filter(
            (s) => !["CANCELLED", "REJECTED", "APPROVED"].includes(s.status)
          );
          if (resultSessions.length <= 0) return resolve(true);
          //Case 1: conflict sanme time with other session of selected
          resultSessions.forEach((i) => {
            let result = resultSessions.filter(
              (s) =>
                i._id != s._id &&
                this.$moment(i.start_time).format("DD/MM/YYYY") ==
                  this.$moment(s.start_time).format("DD/MM/YYYY") &&
                ((this.$moment(s.start_time).valueOf() <=
                  this.$moment(i.start_time).valueOf() &&
                  this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.end_time).valueOf()) ||
                  (this.$moment(s.start_time).valueOf() <=
                    this.$moment(i.end_time).valueOf() &&
                    this.$moment(i.end_time).valueOf() <=
                      this.$moment(s.end_time).valueOf()) ||
                  (this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.start_time).valueOf() &&
                    this.$moment(s.start_time).valueOf() <=
                      this.$moment(i.end_time).valueOf()) ||
                  (this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.end_time).valueOf() &&
                    this.$moment(s.end_time).valueOf() <=
                      this.$moment(i.end_time).valueOf()))
            );
            if (result.length > 0) return resolve(false);
          });

          //Case 2: conflic with Sessions approved
          let sessionsApproved = this.sessions.filter(
            (s) => s.status == "APPROVED"
          );
          if (sessionsApproved.length <= 0) return resolve(true);
          sessionsApproved.forEach((i, idx) => {
            let result = resultSessions.filter(
              (s) =>
                i._id != s._id &&
                this.$moment(i.start_time).format("DD/MM/YYYY") ==
                  this.$moment(s.start_time).format("DD/MM/YYYY") &&
                ((this.$moment(s.start_time).valueOf() <=
                  this.$moment(i.start_time).valueOf() &&
                  this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.end_time).valueOf()) ||
                  (this.$moment(s.start_time).valueOf() <=
                    this.$moment(i.end_time).valueOf() &&
                    this.$moment(i.end_time).valueOf() <=
                      this.$moment(s.end_time).valueOf()) ||
                  (this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.start_time).valueOf() &&
                    this.$moment(s.start_time).valueOf() <=
                      this.$moment(i.end_time).valueOf()) ||
                  (this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.end_time).valueOf() &&
                    this.$moment(s.end_time).valueOf() <=
                      this.$moment(i.end_time).valueOf()))
            );

            if (result.length > 0) return resolve(false);

            if (idx == sessionsApproved.length - 1) return resolve(true);
          });
        } else {
          return true;
        }
      });
    },
    deleteSessions() {
      if (confirm("Bạn có chắc muốn xóa các mục đã chọn ?")) {
        if (!this.selected_sessions || this.selected_sessions.length <= 0)
          return;
        let self = this;
        this.axios
          .delete(self.$root.apiAssetMana + "/sessions/deletes", {
            headers: { "x-auth": this.token },
            data: {
              ids: this.selected_sessions.map((u) => u._id),
            },
          })
          .then((res) => {
            if (res.data.status == "OK") {
              alert("Xóa thành công!");
              self.fetchAllData();
            } else {
              alert("Đã xảy ra lỗi!");
              console.log(res.data.message);
            }
          })
          .catch((err) => {
            alert("Đã xảy ra lỗi!");
            console.log(err);
          });
      }
    },
    fetchUserInfo() {
      let self = this
      if (this.userId && this.token) {
        this.axios
          .get(this.$root.apiUser + "/users/get/" + this.userId, {
            headers: { "x-auth": this.token },
          })
          .then((res) => {
            if (res.data.status == "OK") {
              self.masterUser = res.data.content;
              console.log('masterUser')
              console.log(self.masterUser);
            } else {
              console.log(res.data.message);
            }
          })
          .catch(console.log);
      }
    },
    downloadExcel () {
      let wb = XLSX.utils.book_new();
      let data = [];
      let header = this.headers.map((theader) => theader.text);
      header.unshift('Mã NV')
      let dataExport = this.sessionsAfterFilter;
      dataExport.forEach((session) => {
        let returnRow = {};
        returnRow[header[0]] = session["user_code"];
        returnRow[header[1]] = session["user_name"];
        returnRow[header[2]] = this.resolveOfDateDisplay(session);
        returnRow[header[3]] = this.getOffOrderStatusName(session.props.offType, session.props.pickedHours);
        returnRow[header[4]] = session["description"];
        returnRow[header[5]] = this.getStatusName(session.status);
        returnRow[header[6]] = session.department_name;
        
        data.push(returnRow);
      });

      let dataWS = XLSX.utils.json_to_sheet(data, {
        header: header,
      });
      let wscols = [{ wpx: 140 }];
      header.forEach((f, index) => {
        if (index > 0) wscols.push({ wpx: 120 });
      });
      dataWS["!cols"] = wscols;
      XLSX.utils.book_append_sheet(wb, dataWS, "Report");
      const fileName = this.genExcelFileName();
      XLSX.writeFile(wb, fileName);
    },
    genExcelFileName() {
      let filename = "Nghi phep ";
      filename =
        filename +
        " - " +
        this.$moment(this.range.start).format("DD-MM-YYYY") +
        " den " +
        this.$moment(this.range.end).format("DD-MM-YYYY") +
        ".xlsx";
      return filename;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setColorForOffOrder()
      this.fetchUserInfo()
    });
  },
};
</script>

<style></style>
